import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { validate } from 'uuid'

import HardcodedSearchResults from 'domains/Search/HardcodedSearchResults'
import MemberDirectoryResultsList from 'domains/Search/MemberDirectoryResultsList'
import {
  ResultHighlights,
  ResultHighlightsProvider
} from 'domains/Search/ResultHighlights'
import SearchResultsList from 'domains/Search/SearchResultsList'
import SimilaritySearchResultsList from 'domains/Search/SimilaritySearchResultsList'
import SimilaritySearchTopResultsList from 'domains/Search/SimilaritySearchTopResultsList'
import useSearchResultsFilters, {
  SearchLinkType
} from 'domains/Search/useSearchResultsFilters'

import { ErrorMessage, Loading } from 'components'
import { ShareableSnapshotModal } from 'components/ShareableSnapshotModal'
import NewProgramBanner from 'components/banners/TopBanner/NewProgramBanner'

import {
  SimilaritySearchContent,
  useSearchDocumentsQuery,
  useSimilaritySearchQuery,
  useTrackServerEventMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import useURLParams from 'hooks/useURLParams'

import { getAnonymousId } from 'utils/tracking/segment'

export interface SearchResultsPageProps {
  searchQuery: string
  searchType: string
  programSlug: string
  topicSlug: string
  page: number
  path: string
}

const SearchResultsPageContainer = () => {
  const { getParam } = useURLParams()
  const searchQuery = getParam('q')
  const searchType = getParam('type')
  const programSlug = getParam('program')
  const topicSlug = getParam('topic')
  const page = +getParam('page', '1')
  const path = getParam('path')

  useEffect(() => {
    // reset scroll position when search query changes
    document.getElementById('page')?.scrollTo(0, 0)
  }, [searchQuery])

  return (
    <SearchResultsPage
      searchQuery={searchQuery}
      searchType={searchType}
      programSlug={programSlug}
      topicSlug={topicSlug}
      page={page}
      path={path}
    />
  )
}

export const SearchResultsPage = ({
  searchQuery,
  searchType,
  programSlug,
  topicSlug,
  page,
  path
}: SearchResultsPageProps) => {
  const { currentUser, currentUserLoading, currentUserError } = useCurrentUser()
  const { showAiSidekick, freeAi } = useFeatureFlags()
  const [trackServerEvent] = useTrackServerEventMutation()
  const { pathname } = useLocation()

  const { loading, error, data } = useSearchDocumentsQuery({
    variables: {
      query: searchQuery,
      type: searchType,
      page,
      program: programSlug,
      topic: topicSlug,
      path
    },
    skip: searchType !== SearchLinkType.USER
  })

  const {
    loading: similarityLoading,
    data: similarityData,
    error: similarityError
  } = useSimilaritySearchQuery({
    variables: {
      query: searchQuery,
      type: searchType,
      initiator: path
    },
    skip: !searchQuery || searchType === SearchLinkType.USER
  })

  const searchSuggestions = similarityData?.similaritySearch?.suggestions || []

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const searchId = similarityData?.similaritySearch.id || null

  const searchDocuments = data?.searchDocuments?.results || []
  const searchResultsTotalCount = data?.searchDocuments?.totalCount || 0
  const similarResultsTotalCount = similarityData?.similaritySearch
    ? similarityData.similaritySearch.results.length
    : 0
  const userCan = currentUser?.can || {
    linkToSearchResults: false,
    viewMemberProfile: false,
    viewPost: false,
    viewSearchTabs: false
  }

  useSearchResultsFilters({ searchType, userCan, searchQuery })

  if (currentUserLoading) return <Loading />
  if (currentUserError || error || similarityError) {
    return <ErrorMessage error={currentUserError || similarityError || error} />
  }

  const calculateResultPosition = (page: number, searchPosition: number) => {
    const pagesAfterFirst = page - 1
    const offset = pagesAfterFirst * 10
    return offset + searchPosition
  }

  const handleBaseResultClick = (
    topResult: boolean,
    url: string,
    path: string,
    searchPosition: number,
    resultComponent: string,
    contentType?: string,
    subType?: string | null,
    doc?: SimilaritySearchContent | null
  ) => {
    const resultPosition = calculateResultPosition(page, searchPosition)

    const documentId = doc?.id || ''

    const eventObj = {
      event: 'Content Clicked - Server',
      anonymousId: getAnonymousId(),
      properties: {
        content_sanity_id: validate(documentId) ? doc?.id : null,
        content_title: doc?.title,
        content_type: contentType,
        display_type: 'horizontal_card',
        location: 'search',
        logged_in: !!currentUser,
        content_ccl_local_id: doc?.cclEntityId,
        destination: path, // path value here is the SearchResult click destination
        referrer: pathname,
        relatedIdentifiers: {
          search_query: searchQuery,
          search_result_component: resultComponent,
          search_number_of_results: searchResultsTotalCount + similarResultsTotalCount,
          search_result_position: `${resultPosition}`,
          search_is_top_result: topResult,
          content_mode: 'async'
        }
      }
    }

    try {
      trackServerEvent({
        variables: {
          input: eventObj
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  const handleSimilarityResultClick = handleBaseResultClick.bind(null, false)

  const handleSimilarityResultClickTop = (
    topResult: boolean,
    url: string,
    path: string,
    searchPosition: number,
    resultComponent: string,
    contentType: string,
    subType: string | null,
    doc: SimilaritySearchContent | null
  ) => {
    // Provide default values for string parameters that could be undefined
    const safeResultComponent = resultComponent || ''
    const safeContentType = contentType || ''
    const safeSubType = subType || ''

    // // Call handleBaseResultClick with the safe string values
    handleBaseResultClick(
      topResult,
      url,
      path,
      searchPosition,
      safeResultComponent,
      safeContentType,
      safeSubType,
      doc
    )
  }

  const handleAnchorResultClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    searchPosition: number,
    resultComponent: string,
    contentType?: string,
    subType?: string | null
  ) => {
    const currentHref = e.currentTarget.href || ''
    const currentPath = e.currentTarget.pathname
    // This handler is only used for Member results, and so `topResult` is always false.
    handleBaseResultClick(
      false,
      currentHref,
      currentPath,
      searchPosition,
      resultComponent,
      contentType,
      subType
    )
  }

  return (
    <>
      <NewProgramBanner hasBorder className="mb-6" />

      <ShareableSnapshotModal page="Search" />

      {currentUser ? (
        <ResultHighlightsProvider query={searchQuery} searchType={searchType}>
          <Results
            path={path}
            currentUser={currentUser}
            searchQuery={searchQuery}
            searchType={searchType}
            handleSimilarityResultClickTop={handleSimilarityResultClickTop}
            userCan={userCan}
            similarityLoading={similarityLoading}
            similarityData={similarityData}
            searchSuggestions={searchSuggestions}
            handleAnchorResultClick={handleAnchorResultClick}
            handleSimilarityResultClick={handleSimilarityResultClick}
            searchDocuments={searchDocuments}
            loading={loading}
            page={page}
            searchResultsTotalCount={searchResultsTotalCount}
            showAiSidekick={showAiSidekick}
            freeAi={freeAi}
          />
        </ResultHighlightsProvider>
      ) : (
        <Results
          path={path}
          currentUser={currentUser}
          searchQuery={searchQuery}
          searchType={searchType}
          handleSimilarityResultClickTop={handleSimilarityResultClickTop}
          userCan={userCan}
          similarityLoading={similarityLoading}
          similarityData={similarityData}
          searchSuggestions={searchSuggestions}
          handleAnchorResultClick={handleAnchorResultClick}
          handleSimilarityResultClick={handleSimilarityResultClick}
          searchDocuments={searchDocuments}
          loading={loading}
          page={page}
          searchResultsTotalCount={searchResultsTotalCount}
          showAiSidekick={showAiSidekick}
          freeAi={freeAi}
        />
      )}
    </>
  )
}

const Results = ({
  currentUser,
  searchQuery,
  searchType,
  handleSimilarityResultClickTop,
  userCan,
  similarityLoading,
  similarityData,
  searchSuggestions,
  handleAnchorResultClick,
  handleSimilarityResultClick,
  searchDocuments,
  loading,
  page,
  searchResultsTotalCount,
  showAiSidekick,
  freeAi,
  path
}: {
  currentUser: any
  searchQuery: string
  searchType: string
  handleSimilarityResultClickTop: any
  userCan: any
  similarityLoading: any
  similarityData: any
  searchSuggestions: any
  handleAnchorResultClick: any
  handleSimilarityResultClick: any
  searchDocuments: any
  loading: any
  page: number
  searchResultsTotalCount: number
  showAiSidekick: boolean
  freeAi: boolean
  path: string
}) => {
  return (
    <div className="flex gap-8">
      <div id="list" className="flex w-full max-w-5xl flex-col gap-8">
        <HardcodedSearchResults currentUser={currentUser} searchQuery={searchQuery} />

        {/* ALL TAB */}
        {!searchType && (
          <>
            <SimilaritySearchTopResultsList
              path={path}
              currentUser={currentUser}
              key={`top-1-${searchQuery}`}
              linkType={SearchLinkType.CMS_SECTION}
              searchQuery={searchQuery}
              handleResultClick={handleSimilarityResultClickTop}
              userCan={userCan}
              itemsPerSection={3}
              loading={similarityLoading}
              searchResults={similarityData?.similaritySearch?.results}
              suggestions={searchSuggestions}
            />
            {/* 👇 Only displaying user directory, other content types were migrated to similarity search */}
            {!!currentUser && (
              <MemberDirectoryResultsList
                key={`top-2-${searchQuery}`}
                searchQuery={searchQuery}
                handleResultClick={handleAnchorResultClick}
                userCan={userCan}
              />
            )}
          </>
        )}

        {/* PROGRAM CONTENT TAB */}
        {!!searchType && searchType === SearchLinkType.CMS_SECTION && (
          <SimilaritySearchResultsList
            key={`sim-lessons-1-${searchQuery}`}
            searchQuery={searchQuery}
            handleResultClick={handleSimilarityResultClick}
            userCan={userCan}
            currentUser={currentUser}
            sectionHeader="Program Content"
            linkType={SearchLinkType.CMS_SECTION}
            hideHeader={true}
            loading={similarityLoading}
            searchResults={similarityData?.similaritySearch?.results}
          />
        )}

        {/* COURSES TAB */}
        {!!searchType && searchType === SearchLinkType.COURSE && (
          <SimilaritySearchResultsList
            key={`sim-courses-1-${searchQuery}`}
            searchQuery={searchQuery}
            handleResultClick={handleSimilarityResultClick}
            userCan={userCan}
            currentUser={currentUser}
            sectionHeader="Courses"
            linkType={SearchLinkType.COURSE}
            hideHeader={true}
            loading={similarityLoading}
            searchResults={similarityData?.similaritySearch?.results}
          />
        )}

        {/* ARTIFACTS TAB */}
        {!!searchType && searchType === SearchLinkType.ARTIFACT && (
          <SimilaritySearchResultsList
            key={`sim-artifacts-1-${searchQuery}`}
            searchQuery={searchQuery}
            handleResultClick={handleSimilarityResultClick}
            userCan={userCan}
            currentUser={currentUser}
            sectionHeader="Artifacts"
            linkType={SearchLinkType.ARTIFACT}
            hideHeader={true}
            loading={similarityLoading}
            searchResults={similarityData?.similaritySearch?.results}
          />
        )}

        {/* GUIDES TAB */}
        {!!searchType && searchType === SearchLinkType.GUIDE && (
          <SimilaritySearchResultsList
            key={`sim-guides-1-${searchQuery}`}
            searchQuery={searchQuery}
            handleResultClick={handleSimilarityResultClick}
            userCan={userCan}
            currentUser={currentUser}
            sectionHeader="Guides"
            linkType={SearchLinkType.GUIDE}
            hideHeader={true}
            loading={similarityLoading}
            searchResults={similarityData?.similaritySearch?.results}
          />
        )}

        {/* DIRECTORY TAB */}
        {!!searchType &&
          searchType === SearchLinkType.USER &&
          userCan.viewSearchTabs &&
          !currentUser?.is.trialing && (
            <SearchResultsList
              searchDocuments={searchDocuments}
              searchQuery={searchQuery}
              searchType={searchType}
              loading={loading}
              page={page}
              searchResultsTotalCount={searchResultsTotalCount}
              userCan={userCan}
              handleResultClick={handleAnchorResultClick}
            />
          )}
      </div>

      {!searchType && !!currentUser && (showAiSidekick || freeAi) && (
        <div className="hidden w-full max-w-sm lgr:block">
          <ResultHighlights key={searchQuery} />
        </div>
      )}
    </div>
  )
}

export default SearchResultsPageContainer
