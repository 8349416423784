import { format } from 'date-fns'
import pluralize from 'pluralize'
import { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { PopoverPosition } from 'react-tiny-popover'

import { getContributorTimedPositionAtCompany } from 'domains/Profile/utils'
import Image from 'domains/Sanity/Image'

import { FacePile } from 'components/FacePile'
import { Tag } from 'components/Tag'
import BaseCardGalleryIcon from 'components/icons/BaseCardGalleryIcon copy'
import BaseCardPlayIcon from 'components/icons/BaseCardPlayIcon'

import {
  Artifact,
  BookmarkFolderPartsFragment,
  CclArtifact,
  ConceptCurrentUserPartsFragment,
  CurrentUserPartsFragment,
  ProgramBookmarkPartsFragment,
  useTrackServerEventMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { FULL_DATE_ABBREV_MONTH_FORMAT } from 'utils/date'
import { numberWithCommas } from 'utils/stringUtils'
import { getAnonymousId } from 'utils/tracking/analytics'

import BaseCard, { CardVariant, CardVariants } from './BaseCard'

const THUMBNAIL_COLOR_TO_HEX: { [k: string]: string } = {
  green: '#F8FBE9',
  teal: '#F2FBFB',
  blue: '#F9F9FF',
  orange: '#FFF7F2',
  pink: '#FFF5FA',
  yellow: '#FEFCEC'
}

const DEFAULT_THUMBNAIL_COLOR = THUMBNAIL_COLOR_TO_HEX.pink

export interface ArtifactCardProps {
  // TODO: A future refactor should be to only define artifact prop as CclArtifact
  // (or perhaps even a CclArtifact fragment for this card)
  artifact: Artifact | CclArtifact
  variant?: CardVariant
  currentUser?: CurrentUserPartsFragment | ConceptCurrentUserPartsFragment | null
  swimlaneSlug?: string
  historyState?: {}
  pageLocation?: string
  additionalRelatedIdentifiers?: {}
  isOnboardingFlow?: boolean
  // TODO: The current Artifact GraphQL type does not provide fields for views or comments, so
  // these are added to properties of the card. Depending on how the query is ultimately made, these
  // could be removed if the values become fields on the supplied artifact. If a method similar to
  // the saveCounts is employed, then keeping these as independent properties is appropriate.
  viewCount?: number
  commentCount?: number
  // Bookmark-related props
  bookmark?: ProgramBookmarkPartsFragment
  currentFolder?: BookmarkFolderPartsFragment | null
  bookmarkFolders?: BookmarkFolderPartsFragment[] | undefined
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  restoreBookmark?: (bookmark: ProgramBookmarkPartsFragment) => void
  handleRemoveFromFolder?: (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => Promise<string | null | undefined>
  hideBookmarkButton?: boolean
  customFooter?: ReactNode
  showPublishDateInFooter?: boolean
  bookmarkDropdownPosition?: PopoverPosition
  baseCardClassName?: string
  customHoverMiniCard?: boolean
  impressionTrackingProperties?: { [key: string]: any }
  sectionIndex?: number
  sectionImpressionIndex?: number
  showPremiumIconOverwrite?: boolean
}

const ArtifactCard = ({
  artifact,
  variant = CardVariants.Vertical,
  pageLocation = 'artifact_index',
  bookmark,
  currentFolder,
  bookmarkFolders,
  openAddToBookmarkFolderModal,
  restoreBookmark,
  handleRemoveFromFolder,
  viewCount,
  commentCount,
  additionalRelatedIdentifiers,
  hideBookmarkButton,
  customFooter,
  showPublishDateInFooter,
  historyState,
  bookmarkDropdownPosition,
  baseCardClassName,
  customHoverMiniCard,
  impressionTrackingProperties,
  sectionIndex,
  sectionImpressionIndex,
  showPremiumIconOverwrite
}: ArtifactCardProps) => {
  const { pathname } = useLocation()
  const [trackServerEvent] = useTrackServerEventMutation()
  const { isLoggedIn, currentUser } = useCurrentUser()
  const destination = `/artifacts/${artifact.slug}`
  const artifactSourceId =
    artifact.__typename === 'CclArtifact' ? artifact.sourceId : artifact.id

  const relatedIdentifiers = {
    content_mode: 'async',
    ...additionalRelatedIdentifiers
  }

  const handleTracking = () => {
    trackServerEvent({
      variables: {
        input: {
          event: 'Content Clicked - Server',
          anonymousId: getAnonymousId(),
          properties: {
            content_sanity_id: artifactSourceId,
            content_title: artifact.title,
            content_type: 'artifact',
            destination,
            referrer: pathname,
            location: pageLocation,
            display_type: `${variant}_card`,
            logged_in: isLoggedIn,
            related_identifiers: relatedIdentifiers,
            section_index: sectionIndex,
            section_impression_index: sectionImpressionIndex,
            content_ccl_local_id:
              artifact.__typename === 'CclArtifact'
                ? artifact.id
                : artifact.cclArtifact?.id
          }
        }
      }
    })
  }

  const showPremiumIcon =
    showPremiumIconOverwrite ??
    (!artifact?.isFree && (!isLoggedIn || !!currentUser?.is?.premember))

  return (
    <BaseCard
      contentType="Artifact"
      variant={variant}
      sanityId={artifactSourceId}
      title={artifact.title}
      byline={<ArtifactByline artifact={artifact} />}
      body={artifact.description}
      thumbnail={<Thumbnail artifact={artifact} />}
      horizontalThumbnail={<ArtifactHorizontalThumbnail artifact={artifact} />}
      verticalThumbnail={<ArtifactVerticalThumbnail artifact={artifact} />}
      impressionTrackingProperties={impressionTrackingProperties}
      footer={
        customFooter || (
          <ArtifactFooter
            showPublishDate={showPublishDateInFooter || false}
            artifact={artifact}
            viewCount={artifact.viewCount || viewCount}
            commentCount={artifact.commentCount || commentCount}
          />
        )
      }
      destination={destination}
      historyState={historyState}
      trackCardClick={handleTracking}
      bookmark={bookmark}
      currentFolder={currentFolder}
      bookmarkFolders={bookmarkFolders}
      openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
      restoreBookmark={restoreBookmark}
      handleRemoveFromFolder={handleRemoveFromFolder}
      hideBookmarkButton={hideBookmarkButton}
      bookmarkDropdownPosition={bookmarkDropdownPosition}
      className={baseCardClassName}
      customHoverMiniCard={customHoverMiniCard}
      showPremiumIcon={showPremiumIcon}
    />
  )
}

const ArtifactByline = ({ artifact }: { artifact: any }) => {
  if (!artifact?.authors?.length) return null

  const author = artifact.authors[0]

  const facepileUsers = author
    ? [
        {
          id: author?.id,
          avatarUrl: author?.avatarUrl || author?.avatarPhoto?.imageUrl
        }
      ]
    : []

  return (
    <div className="flex gap-2">
      <div className="flex shrink-0 grow-0">
        <FacePile users={facepileUsers} imageSize="small" />
      </div>
      <div className="text-ellipsis line-clamp-1">
        {author?.name}{' '}
        <span className="text-rb-gray-300">
          {getContributorTimedPositionAtCompany(author)}
        </span>
      </div>
    </div>
  )
}

const ArtifactFormat = ({ format }: { format: string }) => {
  if (format === 'video') {
    return (
      <div className="absolute top-0 h-full w-full flex items-center justify-center z-2">
        <BaseCardPlayIcon />
      </div>
    )
  }

  if (format === 'image') {
    return (
      <div className="absolute top-0 flex items-end justify-end w-full h-full p-2.5 z-2">
        <BaseCardGalleryIcon />
      </div>
    )
  }

  return null
}

const ArtifactVerticalThumbnail = ({ artifact }: { artifact: any }) => {
  if (artifact.thumbnailUrl) {
    return (
      <div
        className="relative h-full rounded-t-xl overflow-hidden border-b border-rb-gray-100"
        style={{
          backgroundColor: artifact.thumbnailBackgroundColor
            ? THUMBNAIL_COLOR_TO_HEX[artifact.thumbnailBackgroundColor]
            : DEFAULT_THUMBNAIL_COLOR
        }}
      >
        {artifact.draftTemplateName && (
          <div className="absolute top-3 left-3 z-2">
            <Tag
              text="Draft this"
              className="bg-white px-3 py-0 rounded-full text-rb-gray-400 font-semibold"
            />
          </div>
        )}
        {artifact.companyLogoUrl && (
          <Image
            className="absolute left-4 bottom-4 rounded-md z-2 shadow-[0px_4px_9px_0px_rgba(15,15,15,0.10)]"
            src={artifact.companyLogoUrl}
            width="55"
            height="55"
            alt={artifact.companyName}
          />
        )}
        <div className="absolute w-[70%] bg-white p-1 rounded-t bottom-[-4px] shadow-[0px_4px_9px_0px_rgba(15,15,15,0.10)] left-[15.5%] z-0">
          <Image
            className="w-full object-cover"
            src={artifact.thumbnailUrl}
            alt={artifact.title || ''}
          />
        </div>
        <ArtifactFormat format={artifact.format} />
      </div>
    )
  }

  return null
}

const ArtifactHorizontalThumbnail = ({ artifact }: { artifact: any }) => {
  if (artifact.thumbnailUrl) {
    return (
      <div
        className="relative h-full overflow-hidden rounded-md"
        style={{
          backgroundColor: artifact.thumbnailBackgroundColor
            ? THUMBNAIL_COLOR_TO_HEX[artifact.thumbnailBackgroundColor]
            : DEFAULT_THUMBNAIL_COLOR
        }}
      >
        {artifact.companyLogoUrl && (
          <Image
            className="absolute left-3 bottom-3 rounded-md z-2 shadow-[0px_4px_9px_0px_rgba(15,15,15,0.10)"
            src={artifact.companyLogoUrl}
            width="55"
            height="55"
            alt={artifact.companyName}
          />
        )}
        <div className="absolute w-[78%] h-[89%] bg-white p-1 rounded-t bottom-[-4px] shadow-[0px_4px_9px_0px_rgba(15,15,15,0.10)] left-[11%] z-0">
          <Image
            className="w-full h-full object-cover object-left-top"
            src={artifact.thumbnailUrl}
            alt={artifact.title || ''}
          />
        </div>
        <ArtifactFormat format={artifact.format} />
      </div>
    )
  }

  return null
}

const Thumbnail = ({ artifact }: { artifact: any }) => {
  if (artifact.thumbnailUrl) {
    return (
      <div
        className="relative w-full h-full overflow-hidden"
        style={{
          backgroundColor: artifact.thumbnailBackgroundColor
            ? THUMBNAIL_COLOR_TO_HEX[artifact.thumbnailBackgroundColor]
            : DEFAULT_THUMBNAIL_COLOR
        }}
      >
        <div className="absolute w-[87%] h-[89%] bg-white p-1 rounded-t bottom-[-4px] shadow-[0px_4px_9px_0px_rgba(15,15,15,0.10)] left-[18%] z-0">
          <Image
            className="h-full w-full object-cover object-left-top"
            src={artifact.thumbnailUrl}
            alt={artifact.title || ''}
          />
        </div>
      </div>
    )
  }
  return null
}

const ArtifactFooter = ({
  artifact,
  viewCount,
  commentCount,
  showPublishDate
}: {
  artifact: any
  viewCount?: number
  commentCount?: number
  showPublishDate?: boolean
}) => {
  return (
    <div className="whitespace-nowrap">
      {showPublishDate && (
        <span>
          Published{' '}
          {format(new Date(artifact.publishDate), FULL_DATE_ABBREV_MONTH_FORMAT)}
        </span>
      )}
      {showPublishDate && (!!viewCount || !!commentCount) && (
        <span className="px-1">·</span>
      )}
      {!!viewCount && (
        <span>
          {numberWithCommas(viewCount)} {pluralize('view', viewCount)}
        </span>
      )}
      {!!viewCount && !!commentCount && <span className="px-1">·</span>}
      {!!commentCount && (
        <span>
          {commentCount} {pluralize('comment', commentCount)}
        </span>
      )}
    </div>
  )
}

export default ArtifactCard
