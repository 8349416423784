import dayjs from 'dayjs'
import playerjs from 'player.js'
import { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useLocation, useParams } from 'react-router-dom'

import { ComplexPortableText } from 'domains/Sanity/PortableText/ComplexPortableText/ComplexPortableText'
import Content404 from 'domains/Sanity/marketingSite/Content404'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { Loading } from 'components'
import BaseBreadcrumbs from 'components/Breadcrumbs/BaseBreadcrumbs'
import { ReactComponent as ChevronLeftIconPodcast } from 'components/Breadcrumbs/BaseBreadcrumbs/base-chevron-left-podcast.svg'
import Button from 'components/Button'

import { Podcast, usePodcastDetailsQuery, useTrackServerEventMutation } from 'gql'

import { getAnonymousId, trackCtaClicked } from 'utils/tracking/analytics'
import { ContentClicked } from 'utils/tracking/generated/types'

import { ReactComponent as ChevronLeftIcon } from 'images/p-chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'images/p-chevron-right.svg'
import Logo from 'images/reforge-logo-black.svg'
import { ReactComponent as ReforgeSymbol } from 'images/reforge-logo-symbol-black.svg'

const PodcastPage = () => {
  const { slug } = useParams<{ slug: string }>()
  const { data, loading } = usePodcastDetailsQuery({
    variables: {
      slug: slug.replaceAll('/', '')
    }
  })
  const page = data?.podcast

  return (
    <MarketingLayout page={page || {}}>
      {loading ? (
        <Loading />
      ) : page ? (
        <PodcastContent podcast={page.podcast} slug={slug} />
      ) : (
        <Content404 />
      )}
    </MarketingLayout>
  )
}

const handleBackClick = (slug: string) => {
  trackCtaClicked({
    cta_location: `/podcast/unsolicited-feedback/${slug}`,
    cta_type: 'button',
    destination: '/podcast/unsolicited-feedback',
    text: 'Back to Podcast'
  })
}

export const PodcastContent = ({ podcast, slug }: { podcast: Podcast; slug: string }) => {
  const [trackServerEvent] = useTrackServerEventMutation()
  const { pathname } = useLocation()

  const ldJson: Record<string, unknown> = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    'mainEntityOfPage': {
      '@type': 'WebPage',
      '@id': `${window.location.origin}${window.location.pathname}`
    },
    'headline': podcast.title,
    'image': podcast.summaryImage?.imageUrl,
    'publisher': {
      '@type': 'Organization',
      'name': 'Reforge',
      'logo': {
        '@type': 'ImageObject',
        'url': Logo
      }
    },
    'author': {
      '@type': 'Organization',
      'name': 'Reforge',
      'sameAs': 'https://www.reforge.com/'
    }
  }

  const listenOnRef = useRef<HTMLDivElement>(null)

  // handle tracking for listen on links
  useEffect(() => {
    const container = listenOnRef.current

    if (!container) {
      return
    }

    const trackListenOnLinkClick = (e: MouseEvent) => {
      const el = e.currentTarget as HTMLAnchorElement

      trackCtaClicked({
        cta_location: `/podcast/unsolicited-feedback/${slug}`,
        cta_type: 'link',
        destination: el.getAttribute('href') ?? '',
        text: el.textContent || ''
      })
    }

    container.querySelectorAll('a').forEach((el) => {
      el.addEventListener('click', trackListenOnLinkClick)
    })

    return () => {
      container.querySelectorAll('a').forEach((el) => {
        el.removeEventListener('click', trackListenOnLinkClick)
      })
    }
  }, [slug])

  // handle tracking for megaphone player events
  useEffect(() => {
    const megaphoneIframeEl = document.querySelector('iframe[src*="megaphone.fm"]')

    if (!megaphoneIframeEl) {
      return
    }

    const player = new playerjs.Player(megaphoneIframeEl)

    const trackingData: ContentClicked = {
      content_title: `podcast_episode_${slug}`,
      content_type: 'other',
      related_identifiers: {}
    }

    player.on('play', () => {
      trackServerEvent({
        variables: {
          input: {
            anonymousId: getAnonymousId(),
            event: 'Content Clicked - Server',
            properties: {
              ...trackingData,
              content_sanity_id: podcast.id,
              referrer: pathname,
              destination: '#',
              related_identifiers: {
                megaphone_play: true,
                megaphone_pause: false,
                content_mode: 'async'
              }
            }
          }
        }
      })
    })
    player.on('pause', () => {
      trackServerEvent({
        variables: {
          input: {
            anonymousId: getAnonymousId(),
            event: 'Content Clicked - Server',
            properties: {
              ...trackingData,
              content_sanity_id: podcast.id,
              referrer: pathname,
              destination: '#',
              related_identifiers: {
                megaphone_play: false,
                megaphone_pause: true,
                content_mode: 'async'
              }
            }
          }
        }
      })
    })
  }, [pathname, podcast.id, slug, trackServerEvent])

  return (
    <>
      <Helmet>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
        ></script>
      </Helmet>

      <article className="relative flex flex-col items-center px-4 py-[6vw] md:px-[4vw] md:py-[4vw]">
        <div className="mx-auto w-full sm:max-w-[75%]">
          <header className="mb-[30px]">
            <div className="mb-8">
              <BaseBreadcrumbs
                className="font-serif text-[2.125rem] font-light tracking-tighter"
                breadcrumbPages={[
                  { title: 'Podcast', path: '/podcast/unsolicited-feedback' }
                ]}
                oneLevel={true}
                backIcon={
                  <ChevronLeftIconPodcast
                    width={11.349}
                    height={24}
                    className="mr-4 fill-rb-black"
                  />
                }
                onClick={() => handleBackClick(slug ?? '')}
              />
            </div>
            <h1 className="mb-5 font-normal tracking-tighter fluid-text-7xl">
              {podcast.title}
            </h1>

            {podcast.ctaButton?.enabled && (
              <Button
                href={podcast.ctaButton.ctaLink!}
                variant="outline"
                shape="rounded-full"
                className="mb-5 h-8 border-rb-teal-200 py-0 px-4 sm:h-10 lg:h-12 lg:text-base"
              >
                {podcast.ctaButton.withReforgeSymbol && (
                  <ReforgeSymbol className="mr-2 h-auto w-4" />
                )}
                {podcast.ctaButton.ctaText}
              </Button>
            )}

            <p className="mb-4 !leading-tight fluid-text-lg">
              <strong className="font-semibold">Hosts:</strong> {podcast.hosts}
            </p>
            <p className="mb-4 !leading-tight fluid-text-lg">
              <strong className="font-semibold">Topics:</strong> {podcast.topics}
            </p>
            <p className="mb-4 !leading-tight fluid-text-base">
              <strong className="font-semibold">Release Date:</strong>{' '}
              {dayjs(podcast.releaseDate).format('MMM DD, YYYY')}
            </p>

            <div className="flex">
              <strong className="mr-2 shrink-0 font-semibold !leading-tight fluid-text-base">
                Listen on:
              </strong>
              <div
                className="block-content [&_h2]:!font-light [&_h3_.font-semibold]:!font-light"
                ref={listenOnRef}
              >
                <ComplexPortableText content={podcast.listenOn} />
              </div>
            </div>
          </header>

          <section className="block-content [&_h2]:!font-light [&_h3_.font-semibold]:!font-light">
            <ComplexPortableText content={podcast.contentBlock} />
          </section>
        </div>
      </article>

      <nav className="mb-5 flex flex-col sm:flex-row">
        <div className="mb-8 w-full px-4 sm:w-1/2 md:px-[4vw]">
          {podcast.nextEpisode && (
            <Link
              to={`/podcast/unsolicited-feedback/${podcast.nextEpisode.slug}`}
              className="flex items-center font-serif text-rb-black no-underline fluid-text-2xl hover:no-underline"
            >
              <ChevronLeftIcon className="mr-4 h-8 w-auto shrink-0" />
              {podcast.nextEpisode.title}
            </Link>
          )}
        </div>
        <div className="mb-8 w-full px-4 sm:w-1/2 md:px-[4vw]">
          {podcast.prevEpisode && (
            <Link
              to={`/podcast/unsolicited-feedback/${podcast.prevEpisode.slug}`}
              className="flex items-center text-right font-serif text-rb-black no-underline fluid-text-2xl hover:no-underline"
            >
              {podcast.prevEpisode.title}
              <ChevronRightIcon className="ml-4 h-8 w-auto shrink-0" />
            </Link>
          )}
        </div>
      </nav>
    </>
  )
}

export default PodcastPage
