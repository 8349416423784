import SeoPageSectionSubtitle from 'domains/Seo/Section/SeoPageSectionSubtitle'
import SeoPageSectionTitle from 'domains/Seo/Section/SeoPageSectionTitle'

import { Loading } from 'components'
import Button from 'components/Button'

import { ArtifactSubtopicSeoPartsFragment, ArtifactWithWorkEntry } from 'gql'

import { trackCtaClicked } from 'utils/tracking/analytics'

import ArtifactSeoCardLarge from '../ArtifactSeoCardLarge'

interface MostPopularArtifactsProps {
  subtopic: ArtifactSubtopicSeoPartsFragment
  artifacts: ArtifactWithWorkEntry[]
  totalCount: number
  loading: boolean
  ctaHref: string
}

const MostPopularArtifacts = ({
  subtopic,
  artifacts,
  totalCount,
  loading,
  ctaHref
}: MostPopularArtifactsProps) => {
  const ctaText = `View all ${subtopic.titlePlural}`

  const onCtaClick = () => {
    trackCtaClicked({
      cta_type: 'button',
      destination: ctaHref,
      cta_location: 'SEO explore subtopic list',
      text: ctaText.toLowerCase(),
      related_identifiers: {
        sanity_type: 'subtopic',
        sanity_id: subtopic.id || '',
        sanity_name: subtopic.title.toLowerCase()
      }
    })
  }

  return (
    <section className="mb-[104px]">
      <SeoPageSectionTitle>Popular {subtopic.title} examples</SeoPageSectionTitle>
      {artifacts.length > 0 && (
        <SeoPageSectionSubtitle>
          Explore {artifacts.length} most popular {subtopic.titlePlural} from top
          companies.
        </SeoPageSectionSubtitle>
      )}

      {loading ? (
        <div className="h-[200px] pt-[100px]">
          <Loading />
        </div>
      ) : (
        <div className="mb-6 grid grid-cols-1 gap-8">
          {artifacts.map((artifact, i) => (
            <ArtifactSeoCardLarge
              artifact={artifact}
              key={artifact.id}
              topArtifact={i === 0}
            />
          ))}
        </div>
      )}

      <p className="mb-2 text-sm leading-[1.5] text-rb-gray-400 md:text-base md:leading-[1.6]">
        View all {totalCount ? `${totalCount} ` : ''}
        examples of {subtopic.titlePlural}.
      </p>

      <Button
        color="teal"
        shape="rounded"
        size="small"
        href={ctaHref}
        onClick={onCtaClick}
      >
        {ctaText}
      </Button>
    </section>
  )
}
export default MostPopularArtifacts
