import { ReactNode, useCallback, useMemo, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import ArtifactSeoTopicLinkSection from 'domains/ArtifactSeo/ArtifactSeoTopicLinkSection'
import ArtifactTopicCarouselSectionWithQuery from 'domains/ArtifactSeo/ArtifactTopicCarouselSectionWithQuery'
import Content404 from 'domains/Sanity/marketingSite/Content404'
import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'
import RelatedBlogPostsCarouselSection from 'domains/Seo/RelatedBlogPostsCarouselSection'
import { SeoTopicSection } from 'domains/Seo/Section/SeoTopicSection/SeoTopicSection'
import SeoPageCtaSection from 'domains/Seo/SeoPageCtaSection'
import SeoPageHero from 'domains/Seo/SeoPageHero'
import { SEOTrackingRelatedIdentifiers } from 'domains/Seo/helpers'

import { Loading } from 'components'
import BaseBreadcrumbs from 'components/Breadcrumbs/BaseBreadcrumbs'
import { ReactComponent as ChevronLeftIcon } from 'components/Breadcrumbs/BaseBreadcrumbs/base-chevron-left.svg'

import {
  ArtifactSubtopic,
  ArtifactSubtopicSeoPartsFragment,
  ArtifactTopic,
  BlogPost,
  PageSeo,
  PageTypeEnum,
  useArtifactSubtopicsQuery,
  useArtifactTopicQuery,
  useTopicsQuery
} from 'gql'

import ShareGraphic from 'images/ReforgeHomeSocialShare.jpg'
import BackgroundImageMobile from 'images/artifact-topic-subtopic-banner-mobile.png'
import BackgroundImage from 'images/artifact-topic-subtopic-banner.png'

export const ArtifactTopicPage = () => {
  const { topic: topicParam } = useParams<{ topic: string }>()

  const { data: topicData, loading: topicDataLoading } = useArtifactTopicQuery({
    variables: {
      slug: topicParam
    }
  })

  const { data: subtopicsData } = useArtifactSubtopicsQuery({
    variables: {
      subtopicSlug: null,
      topicSlug: topicParam
    }
  })

  const { data: topicsData } = useTopicsQuery({
    variables: {
      slug: topicParam
    }
  })

  const topic = topicData?.artifactTopic
  const subtopics = subtopicsData?.subtopics
  const topics = topicsData?.topics

  const handleRender = (content: ReactNode) => {
    if (!topic) {
      return <Content404 />
    }

    if (topic.isDeprecated) {
      return <Redirect to={topic.redirectTo!} />
    }

    return content
  }

  const page: GenericPageType = useMemo(() => {
    if (topic) {
      const topicName = topic.title === 'AI' ? topic.title : topic.title.toLowerCase()
      const title = `${topicName} Templates and Examples - Reforge`
      const description = `Explore ${topic.totalArtifactCount} ${topicName} resources from industry experts. Explore ${topicName} templates and examples from top industry experts.`

      return {
        title,
        seo: {
          metaTitle: title,
          metaDesc: description,
          shareTitle: title,
          shareDesc: description,
          shareGraphic: { asset: ShareGraphic },
          shareGraphicAlt: title,
          type: 'website' as PageTypeEnum
        } as PageSeo
      }
    }

    return {}
  }, [topic])

  return (
    <MarketingLayout page={page}>
      {topicDataLoading ? (
        <Loading />
      ) : (
        handleRender(
          <ArtifactTopicPageContent
            topic={topic!}
            subtopics={subtopics}
            topics={topics}
          />
        )
      )}
    </MarketingLayout>
  )
}

interface ArtifactTopicPageContentProps {
  topic: ArtifactTopic
  subtopics?: ArtifactSubtopic[] | null
  topics?: ArtifactTopic[]
}

export const ArtifactTopicPageContent = ({
  topic,
  topics,
  subtopics
}: ArtifactTopicPageContentProps) => {
  const topicName = topic.title === 'AI' ? topic.title : topic.title.toLowerCase()
  const title = `Real ${topicName} documents from top companies`
  const subtitle = `Explore ${topic.totalArtifactCount} examples of real ${topicName} documents from top companies.`
  const ctaText = `Explore ${topicName} resources`
  const ctaHref = `/artifacts?topics=${topic.slug}`
  const tracking: SEOTrackingRelatedIdentifiers = {
    sanityType: 'topic',
    sanityId: topic.id || '',
    sanityName: topic.title.toLowerCase()
  }
  const [relatedBlogPosts, setRelatedBlogPosts] = useState<{
    [key: string]: BlogPost[]
  }>({})

  const onDataReceived = useCallback(
    ({ subtopic }: { subtopic: ArtifactSubtopicSeoPartsFragment }) => {
      setRelatedBlogPosts((posts) => ({
        ...posts,
        [subtopic.slug]: subtopic.relatedBlogPosts || []
      }))
    },
    []
  )

  const orderedBlogPosts = useMemo(() => {
    // maybe use this method to sort the blog posts in a more meaningful way
    // currently, it returns a flat list of all related blog posts in order of subtopicsToDisplay, duplicates removed
    const posts: BlogPost[] = []
    topic.subtopicsToDisplay?.forEach((subtopicSlug) => {
      const subtopicPosts = relatedBlogPosts[subtopicSlug]
      subtopicPosts?.forEach((post) => {
        if (!posts.find((p) => p.id === post.id)) {
          posts.push(post)
        }
      })
    })

    return posts
  }, [relatedBlogPosts, topic.subtopicsToDisplay])

  return (
    <>
      <BaseBreadcrumbs
        className="mr-5 pb-14 pl-4 md:pl-[4vw] md:pb-8"
        backIcon={
          <ChevronLeftIcon width={15} height={16} className="mr-4 fill-rb-black" />
        }
        breadcrumbPages={[
          { title: 'Artifacts', path: '/artifacts' },
          { title: topic?.title ?? '', path: `/artifacts/c/${topic?.slug ?? ''}` }
        ]}
      />
      <SeoPageHero
        title={title}
        subtitle={subtitle}
        ctaText={ctaText}
        ctaHref={ctaHref}
        updatedAt={topic.lastUpdate}
        bgImage={BackgroundImage}
        bgImageMobile={BackgroundImageMobile}
        tracking={tracking}
      />

      <section className="px-4 pb-8 pt-20 md:px-[4vw]">
        <div className="mx-auto max-w-[1024px]">
          {topic?.subtopicsToDisplay
            ?.slice(0, 1)
            ?.map((subtopicSlug) => (
              <ArtifactTopicCarouselSectionWithQuery
                subtopicSlug={subtopicSlug}
                topicTitle={topicName}
                key={subtopicSlug}
                onDataReceived={onDataReceived}
              />
            ))}

          {topics && topics.length > 0 && (
            <ArtifactSeoTopicLinkSection
              topics={topics}
              title="Browse by category"
              tracking={tracking}
            />
          )}

          {topic?.subtopicsToDisplay
            ?.slice(1, 2)
            ?.map((subtopicSlug) => (
              <ArtifactTopicCarouselSectionWithQuery
                subtopicSlug={subtopicSlug}
                topicTitle={topicName}
                key={subtopicSlug}
                onDataReceived={onDataReceived}
              />
            ))}

          <SeoPageCtaSection
            tracking={tracking}
            ctaText="Explore membership"
            ctaHref="/membership"
            ctaLocation="SEO topic page"
          />

          {topic?.subtopicsToDisplay
            ?.slice(2)
            ?.map((subtopicSlug) => (
              <ArtifactTopicCarouselSectionWithQuery
                subtopicSlug={subtopicSlug}
                topicTitle={topicName}
                key={subtopicSlug}
                onDataReceived={onDataReceived}
              />
            ))}

          {orderedBlogPosts && orderedBlogPosts.length > 0 && (
            <RelatedBlogPostsCarouselSection blogPosts={orderedBlogPosts} />
          )}

          {subtopics && subtopics.length > 0 && (
            <SeoTopicSection
              title="Other categories"
              type="subtopics"
              subtopics={subtopics}
            />
          )}
        </div>
      </section>
    </>
  )
}

export default ArtifactTopicPage
