import { useEffect, useState } from 'react'

import Tabs from 'components/Tabs'

import { MAX_WIDTH_TAILWIND_TL } from 'constants/breakpoints'

import {
  ArtifactAuthorProfileFragmentFragment,
  ExpertUserProfileFragmentFragment
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'
import { trackNavigationClicked, trackUserProfileViewed } from 'utils/tracking/analytics'

import AuthorProfileSection from './AuthorProfileSection/AuthorProfileSection'
import SocialsAndCTA from './AuthorProfileSection/SocialsAndCTA'
import AboutTab from './Tabs/About'
import ExperienceTab from './Tabs/Experience/Experience'
import LatestOverviewTab from './Tabs/LatestOverview'

export interface CreatorProfileProps {
  authorProfile: ArtifactAuthorProfileFragmentFragment
  userProfile: ExpertUserProfileFragmentFragment
}

const CreatorProfile = ({ authorProfile, userProfile }: CreatorProfileProps) => {
  const [activeTab, setActiveTab] = useState('Latest')
  const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_TL})`)
  const { isLoggedIn } = useCurrentUser()

  useEffect(() => {
    trackUserProfileViewed({
      profile_user_name: userProfile.fullName || '',
      profile_user_external_id: userProfile.externalId || '',
      logged_in: isLoggedIn,
      profile_user_kind: userProfile.kind || ''
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTabClick = (tab: string) => {
    trackNavigationClicked({
      location: 'expert_profile',
      text: tab.toLowerCase(),
      type: 'button'
    })

    if (isMobileView && tab === 'Overview') {
      setActiveTab('Latest')
      return
    }
    setActiveTab(tab)
  }
  return (
    <div
      className={cn(
        'flex md:gap-16 flex-col md:flex-row h-screen tl:overflow-hidden w-full relative',
        !isLoggedIn && 'px-4 pb-4 md:px-[4vw]'
      )}
    >
      <div className="md:overflow-auto flex flex-col w-full md:w-[305px] tl:w-[405px] md:pb-40 h-screen">
        <AuthorProfileSection userProfile={userProfile!} authorProfile={authorProfile} />

        <div className="sm:hidden my-6">
          <SocialsAndCTA userProfile={userProfile!} authorProfile={authorProfile} />
        </div>
      </div>

      <div className="md:overflow-auto flex-1 pb-56 h-screen">
        <div className="flex-1 flex flex-col">
          <div className="sticky -top-[2px] py-4 z-3 bg-white">
            <Tabs
              size="small"
              allowHorizontalScroll={false}
              tabs={[
                {
                  label: isMobileView ? 'Overview' : 'Latest',
                  onClick: () => handleTabClick(isMobileView ? 'Overview' : 'Latest'),
                  isActive: activeTab === 'Latest'
                },

                {
                  label: 'Experience',
                  onClick: () => handleTabClick('Experience'),

                  isActive: activeTab === 'Experience'
                },
                {
                  label: 'About',
                  onClick: () => handleTabClick('About'),
                  isActive: activeTab === 'About'
                }
              ]}
            />
          </div>

          {activeTab === 'Latest' && (
            <LatestOverviewTab
              authorProfile={authorProfile!}
              userProfile={userProfile!}
              overviewMode={isMobileView}
            />
          )}
          {activeTab === 'About' && <AboutTab userProfile={userProfile!} />}
          {activeTab === 'Experience' && (
            <ExperienceTab authorProfile={authorProfile} userProfile={userProfile} />
          )}
        </div>
      </div>
    </div>
  )
}

export default CreatorProfile
