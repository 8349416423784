import { useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import {
  CourseDashboardCourseBlockPartsFragment,
  CourseDashboardCourseBlockUnitPartsFragment,
  CourseDashboardCoursePartsFragment,
  CourseDashboardCourseSessionPartsFragment
} from 'gql'

import {
  useContentMode,
  useTrackCourseDashboardContentClicked
} from 'utils/contentTrackingUtils'
import { buildCourseDashboardLinkFromPath } from 'utils/courseUtils'

import { ReactComponent as MapIcon } from 'images/map.svg'
import { ReactComponent as CheckmarkIcon } from 'images/p-checkmark.svg'

interface CourseContentBlockUnitProps {
  unit: CourseDashboardCourseBlockUnitPartsFragment
  course: CourseDashboardCoursePartsFragment
  courseBlock: CourseDashboardCourseBlockPartsFragment
  courseSession?: CourseDashboardCourseSessionPartsFragment
  index?: number
  courseBlockIndex?: number
  size?: number
}

const CourseContentBlockUnit = ({
  courseSession,
  courseBlock,
  course,
  unit,
  index = 0,
  courseBlockIndex = 0,
  size
}: CourseContentBlockUnitProps) => {
  const history = useHistory()
  const contentMode = useContentMode({ courseSession }) || ''
  const trackCourseDashboardContentClicked = useTrackCourseDashboardContentClicked()

  if (!unit) return null

  const onUnitClick = () => {
    const destination = buildCourseDashboardLinkFromPath({
      courseSlug: course.slug,
      sessionId: courseSession?.id,
      guideSlug: unit.slug
    })

    trackCourseDashboardContentClicked({
      destination,
      contentType: 'guide',
      contentTitle: unit.title || '',
      contentSanityId: unit.id,
      contentCclLocalId: unit.guide?.id,
      contentIndex: index,
      courseBlockIndex,
      course,
      courseSession,
      courseBlock,
      contentMode
    })

    history.push(destination)
  }

  return (
    <div
      data-test="course_block_unit"
      id={unit.slug || ''}
      className="group flex cursor-pointer items-center"
      role="button"
      onClick={onUnitClick}
      tabIndex={0}
      onKeyUp={onUnitClick}
    >
      <div
        className={twMerge(
          'group relative mr-6 flex h-[50px] min-w-[50px] items-center justify-center rounded-xl bg-rb-gray-50 group-hover:bg-rb-orange-25',
          unit.completed &&
            'bg-rb-success-50 border-rb-success-100 border-1.25 group-hover:bg-rb-success-50'
        )}
      >
        {unit.completed ? (
          <CheckmarkIcon className="h-[16px] w-[16px] text-rb-success-200" />
        ) : (
          <MapIcon className="h-[16px] w-[16px] text-rb-gray-500" />
        )}
        {index !== undefined && size !== undefined && index !== size - 1 && (
          <div className="absolute left-[25px] top-[50px] h-full w-0.5 bg-rb-gray-50" />
        )}
      </div>
      <div className="flex flex-col gap-1">
        <RfParagraphSmall>
          <span className="text-rb-gray-400">{unit.title}</span>
        </RfParagraphSmall>
        {unit.estimatedTimeInMinutes && (
          <RfParagraphMini>
            <span className="text-rb-gray-300">{unit.estimatedTimeInMinutes} min</span>
          </RfParagraphMini>
        )}
      </div>
    </div>
  )
}

export default CourseContentBlockUnit
